/* Global styles - Stylesheet for the layout of the UI.*/
*,
*::before,
*::after {
    box-sizing: border-box; 
}
.chat-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    width: 275px;
    height: 100%;
    border-left: 1px solid #ddd;
    flex-basis: 20%;
    /* height: 100vh; Full height of the viewport */
    overflow-y: auto;
    scrollbar-width: none;
    flex-shrink: 0;

}

@media (max-width: 768px) {
    .chat-history {
        width: 50%;
        flex: 0 0 auto;
        position: fixed;
        transition: transform 0.3s ease-in-out;
        transform: translateX(100%);
        z-index: 5;
        height: 100%;
    }
}
  
.container {
    display: flex;
    flex-direction: row; /* Aligns children (.Sidebar, .main-content, .chat-history) horizontally */
    height: 100vh; /* Ensures the container is as tall as the viewport */
    width: 100%; /* Ensures the container takes up the full width */
    align-items: stretch; /* Stretches children to fill the container height */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */ 
    overflow-x: hidden; /* This ensures the sidebar doesn't overflow the screen  and require horizontal scrolling when the sidebar is hidden */
}   

/* General styles for .left-sidebar */
.left-sidebar {
    /*position: fixed; /* Ensures the sidebar is fixed relative to the viewport - this causes main content area to be too wide, behind Sidebar*/
    display: flex; /* Use flex display to utilize flexbox properties */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    /* width: 250px; */
    width: 275px;
    overflow-y: auto; /* Allows scrolling for overflow content */
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    /*justify-content: space-between;*/
    height: 100%;
    /*padding-bottom: 20px; */
    scrollbar-width: none; /* hide scrollbar Firefox */
    -ms-overflow-style: none; /* hide scrollbar for Internet Explorer and Edge */
    flex-basis: 20%;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
    .left-sidebar {
        flex: 0 0 auto;/* This ensures the sidebar doesn't grow or shrink and sets width at 170px for small screens*/
        position: fixed;
        transition: transform 0.3s ease-in-out;/* smooth animation for the slide in and out */
        z-index: 5;/* Ensure the z-index is high enough to slide over other content */
    }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.left-sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar-close-button {
    /* Default display property for mobile, assuming you want it visible */
    display: block; /* Show the sidebar close button on smaller screens */

    /* Position your close button */
    @media (max-width: 768px) {
        /*display: block; /* Only show the close icon on smaller screens */
        position: absolute; /* Adjust as necessary */
        top: 0px; /* Adjust as necessary - the smaller the number the close to the 'top'*/
        right: 10px; /* Adjust as necessary */
        z-index: 10; /* Make sure it's above other content */
        font-size: 24px; 
        cursor: pointer; 
        padding: 5px; 
    }
    /* For larger screens, hide the close button */
    @media (min-width: 769px) {
        display: none; /* Hide the sidebar close button on larger screens */
    }
}

/* Global styles for all logos */
.logo {
    max-width: 200px; /* Adjust this value to set the width of your logo */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Ensure it's a block-level element to respect width and height */
    text-align: center;
    color: white;
}  

/* Left-sidebar logo styles */
#left-sidebar-logo {
    width: 180px; /* Smaller max-width for sidebar */
    padding: 10px 0 0 0;
    /* Other specific styles that would override .logo styles if necessary */

    @media (max-width: 768px) {
        /*display: none
        /*max-width: 125px; /* Smaller max-width for sidebar on smaller screens */
    }
}
/* Styles for the logo within the chat interface */
.message-logo img {
    width: 55px; /* Adjust this width as needed */
    height: auto; /* Maintain aspect ratio */
    display: block;
}

.message-logo {
    border-radius: 50%; /* Circular shape */
    display: flex; /* Use flexbox for alignment */
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
/* Styling here adjusts the text below the logo (impacts #maia-text in AuthStyles too) */
#maia-text {
    text-align: center;
    font-size: 16px; /* This size may still work well on mobile */
    color: #333;
    padding-bottom: 10px; /* Add some padding below the text */

    @media (max-width: 768px) {  
        font-size: 14px;  /* Adjust font size as needed */
        color: #333;  /* Adjust color as needed */
        padding: 5px; /* Add some padding above and below the text */
    }
}

.search-bar {
    width: 70%;
    height: 40px;
    padding: 10px;
    margin: 10px 0; /* This will provide a margin top and bottom, but not left and right, since flexbox of sidebar is handling centering */
    border-radius: 5px;
    border: 1px solid #ddd;

    @media (max-width: 768px) {
        width: 70%;
        height: 20px;
        padding: 10px;
        margin: 10px auto;
        border-radius: 5px;
        border: 1px solid #ddd;  
    }
}

.feature-list {
    /* Default list styles */
    list-style: none;
    width: 70%; /* This controls the width of the feature-list items, relative to the sidebar container */
    padding: 0;

}

/* List Item default styles */
.feature-list li {
    box-sizing: border-box;
    /* width of the feature list items is set above*/
    height: 40px;
    padding: 5px; /* Adjusts the padding around the text inside the list boxes*/
    border: 1px solid #ddd;
    margin: 10px 0; /* This sets top and bottom margins to 10px and left and right margins to 0 */
    border-radius: 10px;
    text-align: center;
    list-style-type: none; /* Removes the default list item bullet */
    display: flex; /* To center content vertically and horizontally */
    align-items: center; /* This will vertically center the text */
    justify-content: center; /* This will horizontally center the text */
    cursor: pointer; /* Change cursor to pointer to indicate clickable items */

    @media (max-width: 768px) {
        /* Ensure the total width including padding and margin does not exceed the container width */
        height: 40px;
    }
}

.feature-list li:hover {
    background-color: #f0f0f0;
}

.feature-list .active-mode {
    border: 2px solid green;
  }

/* this is the the modal container entire gray area that contains the 'content area with the 'file-name' and 'remove-attachment-btn' */
.modal {
    /*display: flex; /* Hidden by default, display block to show */
    display: inline-block; /* Display inline-block to fit in the layout next to sidebar */
    flex-direction: column; /* Stack children vertically */
    flex-grow: 1; /* Allow the modal to grow as needed */
    position: initial; /* Change position to be relative to the document flow */
    /*left: 5px; */
    /*top: 1px; /* - this was the setting before I changed it to try and get it to adjust downward rather than upward*/
    /*bottom: 50px; /* Override the bottom: 0; from the .modal-content class */
    padding: 10px; /* this is padding around the modal content and the inside edge of the model area. 5px 5px 10px 5px*/
    width: 100%; /* Fixed width relative to its container (sidebar) */
    background-color: rgb(248, 244, 244); /* Fallback color */
    vertical-align: top; /* Align to the top */
    /*overflow-y: visible; /* Ensures content extends the height */


    @media (max-width: 768px) {
        /*max-width: 200px;*/ 
    }
}
  
/* Sytles for the modal content does not seem to impact itmes with their own syling like the 'file-name' and 'remove-attachment-btn' */
.modal-content {
    display: flex; /* Use flexbox, which styles how the children inside the model display*/
    position: relative; /* This allows us to position the close button relative to the modal-content */
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Align children to the start (left), I think you need 'display: flex' for this to work*/
    background-color: #fefefe;
    /*margin: 5px;*/ 
    padding: 0px, 5px, 5px, 5px; /* this is padding around the modal content and the inside edge of the model area. 5px 5px 10px 5px*/
    border: 1px solid #888;
    border-radius: 10px;
    overflow: hidden; /* Ensures the file name is not visible if it's too long */
    width: 100%; /* Width of the model relative to the '.modal' container*/
    height: 100%; /* Width of the model relative to the '.modal' container*/
}

.modal-header {
    font-size: .85rem; /* Adjust font size as needed */
    font-weight: bold; /* Makes the text bold */
    padding-top: 5px; /* Add some padding between the header and the top of the modal*/
    padding-bottom: 0px; /* Add some padding */
    color: #333; /* Set the text color */
    width: 100%; /* Ensure it spans the full width */
    text-align: center; /* Align the text to the left */
    /*border-bottom: 1px solid #e5e5e5; /* Optional: adds a separator line */
}
  
/* This is an unordered list for the list of file names, but needed to be styled so it didn't take up space inside the modal */
ul {
    list-style-type: none; /* Removes the bullet points */
    padding-left: 5px; /* Controls padding for the 'No documents..." too. */
    margin: 0; /* Removes default margin */
    max-width: 100%; /* This ensures the ul does not exceed its parent's width */
    /*overflow: hidden; /* taking this out fixed the issue I was having with the modal placement covering content in the sidenav */
}

.modal-file-item {
    display: flex;
    /*position: relative; */
    align-items: center; /* Alighs items vertically to the center */
    /*align-items: flex-start; /* Aligns items vertically to the top of the flex container */ 
    /*justify-content: start; /* Aligns items to the start of the flex container. changed from flex-start*/
    padding: 7px 3px 5px 5px; /* this is padding around the modal content and the inside edge of the model area*/
    box-sizing: border-box; /* Include padding in the width calculation */
}

.modal-file-item input[type="checkbox"] {
    /* Style your checkbox here */
   margin-right: 5px; /* Space before the file name */
   /*flex: 0 0 auto; /* Checkbox does not grow or shrink */
   /*align-self: center; /* Aligns the checkbox itself to the center */
   transform: translateY(4px); /* Adjust the value as needed to align the checkboxes with the the file names */
   margin-left: 0px; /* Space after the file name */
   padding: 0; /* Remove padding */
}

.modal-file-item .file-name {
    position: relative; /* This allows us to position the close button relative to the modal-content */
    overflow: hidden;
    text-align: left; /* Aligns the file name to the left next to the check boxes*/
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .95rem;
    color: #a09c9c;
    top: 2px; /* This moves the file name down a bit to align with the checkbox */
    /*margin: 0px; /* Space after the file name, provide room for the x button */   
}
  
.modal-file-item .remove-attachment-btn {
    cursor: pointer;
    font-size: 1rem; /* Adjust as needed */
    transform: translateY(-5%); /* Moves the x vertically within the .modal-file-item*/
    background: none; /* Makes the background transparent */
}
/* This is the X to close the Modal */  
.close {
    cursor: pointer;
    font-size: 1.4em;
    background: none; /* Makes the background transparent */
    transform: translateY(-0%); /* Moves the x vertically within the .modal-file-item*/
    border: none; /* Removes the border */
    border-radius: 50px;
    position:absolute; /* Position it relative to the modal-content */
    top: -5px; /* Move it from the top edge of the modal-content */
    right: 5px; /* Move it from the right edge of the modal-content */
    color: #727272;
    padding: 5px; /* Increase padding to make the clickable area larger */
    display: inline-block;
    text-align: center;
}

.close:hover,
.close:focus {
    color: #555;
    outline: none;
   /* cursor: pointer;*/
}

.user-section {
    display: flex;
    position: relative; /* This makes it a reference for the absolute positioning of the dropdown */
    align-items: center; /* Aligns the content vertically */
    justify-content: flex-start;  /* Align children to the start/left */
    padding: 10px; /* Adjust this to create space above and below the user profile */
    margin-top: auto;   /* Push the user section to the bottom */
} 

.user-avatar, .message-avatar {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    text-align: center;
    /* background-color: #ff9900;  This is an orange background color */
    background-color: #1490af;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0; /* This ensures the flex items don't shrink below their content size */
    align-items: center; /* Aligns the content vertically */
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: 18px; 
    margin-right: 10px; /* Add some space between the items */
    margin-left: 10px; /* Add some space between  */
}

@media (max-width: 768px) {
    .user-avatar, .message-avatar {
        width: 5vw; /* Scales with the viewport width */
        height: 5vw; /* Scales with the viewport width */
        min-width: 30px; /* Ensures it doesn't get too small */
        min-height: 30px; /* Ensures it doesn't get too small */
    }
}
.user-name {
    flex: 1; /* This ensures name takes the available space */
    margin-right: 20px; /* Space between name and settings */
    font-size: 16px;
}

.user-settings {
    display: inline-block;
    vertical-align: middle; /* this won't have any effect on inline-block elements unless they are within a line-height context that's different from the element's height.*/
    cursor: pointer;
    width: 30px;  /* Set a fixed width */
    line-height: 50px;  /* Equal to the height of the element */
    color: #333; /* Ensuring the '...' dots have a color that's visible against the background */
    font-size: 24px; /* Making the dots a bit bigger for visibility */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}
.settings-dropdown {
    position: absolute;
    right: -10px;
    bottom: 65%; /* Position it above the user section */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
  
  .settings-dropdown button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
/* This adds background color to the selection when cursor is over it */
.settings-dropdown button:hover {
    background-color: #f0f0f0;
  }
  
/* Desktop and general styles */
.main-content {
    flex: 1;  /* This ensures it takes up the remaining width */
    display: flex; /* this seems to be a key line to position the text field at the bottom */
    flex-direction: column; 
    align-items: stretch;      /* Ensure that children width can be controlled by max-width */
    padding: 10px 5px 5px 5px; /* Adjust the padding to position the chat text within the main contant area (top, right, bottom, left)*/
    width: 100%; /* Takes up the full width available of its parent container - flex 1 should be sufficient here*/
    box-sizing: border-box; /* Ensure padding is included in the width */
    overflow-y:auto; /* Allows the main content to scroll independently of the sidebar and chat history*/
    height:100vh; /* Ensures the main content is at least as tall as the viewport */

    @media (max-width: 768px) {
        margin: 0; /* Remove the margin on smaller screens */
        min-height: auto; /* Ensures the main content is at least as tall as the viewport */
        /* Ensure the justify-content is set appropriately, perhaps 'flex-start' */
        /*justify-content: flex-start; /* Changed from flex-end to flex-start - took this out when trouble shooting Auth, not sure about it*/
    }
}

.scrollable-messages {
    flex: 1; /* Allows this container to grow and fill available space */
    overflow-y: auto; /* Enables vertical scrolling */
    padding-bottom: 10px; /* Space for the input section */
    /* Adjust padding/margin as needed */
}

/* Container for each message */
.message-container {
    display: flex;
    align-items: flex-end; /* Aligns the icon and logo vertically relative to the container, so 'flex-end' is at the bottom */
    margin-bottom: 15px;
    justify-content: center; /* Controls the horizontal alignment of its children (since the default flex-direction is row) 'flex-start' aligns children at the start of the container*/

    /*@media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end; /* Align to end for Maia and user on small screens 
    }*/
}

.message-container.user {
    justify-content: flex-start; /* Controls the horizontal alignment of the user's message within the message-container. 'flex-start' aligns user's messaage at the start of the container - to the left side of the screen */
    padding-left: 5px; /* Add some space on the right of the user message */
    text-align: left; /* Align messages to the left for user messages*/
    align-items: flex-end; /* Align to bottom of message for user messages on small screens */
}

.message-container.maia {
    /*background-color: #e0eaf1; */
    justify-content: flex-end; /* Align Maia messages within the message-container */
    padding-right: 5px; /* Add some space on the right of the user message */
    text-align: left; /* Align Maia messages to the left */
    align-items: flex-end; /* Align to end for user messages on small screens */
}

/* Message text styling */
.message-content {
    max-width: 60%; /* Control the max width of the message */
    padding: 15px 15px;
    border-radius: 18px; 
    font-size: 0.9rem;
    margin: 0 5px; /* Ensure there's space around the message */
}
/* Styles specific to the sender for message background */
.message-container.user .message-content {
    background-color: #EFF2F5;

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.message-container.maia .message-content {
    background-color: #D8E6F3;

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.loading-container {
    position: relative;
    text-align: right; /* Aligns content to the right */
    padding-bottom: 25px; /* This padding moves the word 'Loading...' from the bottom, giving space for the spinner */
    }
@keyframes spin {
    100% {
      transform: rotate(360deg);
    }
}

.loading-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

/* Styles for the message text */
.message-text-center {
    text-align: center;
    margin-bottom: 5px; /* Space between the centered text and the buttons below */
}

/* URL actions */
.message-actions {
    display: flex;
    gap: 10px;
    padding: 10px; /* Increase padding inside the container */
    /* Or use margin to increase space around the container */
    /*margin: 5px; /* Increase space around the container */
}
/* Styles for the URL actions - padding and font size adjust the size of the buttons */
.message-actions a, .message-actions button.message-button {
    text-decoration: none;
    padding: 5px 5px; /* Vertical and horizontal padding inside the buttons */
    border-radius: 4px;
    background-color: #007BFF;
    color: #FFFFFF; /* This is the color of the text in the button */
    transition: background-color 0.2s; /* Transition effect for the background color */
    cursor: pointer; /* Changes cursor to a 'hand pointer' to show that the button is clickable */
    border: none; /* Removes default button border */
}

.message-actions a:hover, .message-actions button.message-button:hover {
    background-color: #0056B3;
}

.input-section {
    margin-top: auto; /* This will push the input section to the bottom. Make sure this is the last child of .main-content if you want it to be at the bottom. */
    position: relative;  /* This allows us to position the buttons inside the text-field using absolute positioning */
    width: 100%; /* Adjust as needed - this sets the width of the text-field (tg: not sure it does) */
    box-sizing: border-box; /* This impacts how the dimensions of the element are calculated and here ensures the padding is included in the width and height of the box, so it doesn't exceed the content area */
}

.checkbox-option {
    align-self: flex-start; /* Aligns with the left edge of the flex container */
    width: calc(100% - 40px); /* Adjusts width considering padding of .text-field-container */
    padding-left: 20px; /* Aligns with the padding of the .text-field-container */
    box-sizing: border-box;
}

.text-field-container {
    position:relative; /* This allows us to position the buttons inside the text-field using absolute positioning */
    display: flex; /* Establishes a positioning context for absolute positioning */ 
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Aligns content inside this container vertically */
    width: 100%; /* Adjust as needed */
    padding: 0px 20px 0px 20px; /* Adjust the padding to position the text-field relative to its area at the bottom of the main content area (top, right, bottom, left)*/
    min-height: 40px; /* Adjust as needed */
    box-sizing: border-box; /* This ensures the padding is included in the width and height of the box, so it doesn't exceed the content area */
}

.text-field {
    flex-grow: 1; /* This ensures the text-field takes up the remaining space */
    border: 2px solid #d9d7d7;
    width: 100%; /* Adjust as needed */ 
    min-height: 40px; /* Adjust as needed */
    overflow: hidden; /* Hide scrollbar */
    border-radius: 10px; /* This rounds the corners of the box - adjust as needed */
    padding: 10px 60px 10px 50px; /* Adjust the padding to potiion the placeholder text and user input text within the text-fieled (top, right, bottom, left)*/
    box-sizing: border-box; /* This ensures the padding is included in the width and height of the box, so it doesn't exceed the content area */
    background-color: #fafafa; /* New Line: Adding very light gray background color */
}

.text-field.file-upload-mode {
    padding: 25px 55px 10px 50px; /* Adjust the padding to ensure text doesn't overlap the buttons (top, right, bottom, left)*/
}

.text-field.analyze-mode {
    padding-left: 100px; 
}    

.text-field.bom-mode {
    /* Unique styling for BOM Analysis mode before file is selected */
    /*background-color: #f0f0f0; /* Example: light grey background */
    /*padding-left: 100px; /* Adjust as necessary, perhaps to make room for icons or additional text */
    border: 2px dashed/* #013c7a; /* Example: dashed border with a blue color */
}

.text-field.bom-analyze-mode {
    /* Unique styling for BOM Analysis mode after file is selected */
    /*background-color: #f0f0f0; /* Example: light grey background */
    padding-left: 100px; /* Adjust as necessary, perhaps to make room for icons or additional text */
    border: 2px solid/* #013c7a; */
}
.text-field.sqs-mode {
    /* Unique styling for Supply Matching Analysis mode before file is selected*/
    /*background-color: #e0e0ff; /* Example: light blue background */
    /*padding-left: 100px; /* Adjust similarly if you need to align with BOM mode or differentiate further */
    border: 2px dashed /*#013c7a; /* Example: dashed border with an orange color */
}

.text-field.sqs-analyze-mode {
    /* Unique styling for when in SQS Mode after file is selected from 'Uploaded Files' */
    /*background-color: #e6e6ff; /* Example: a different light blue to differentiate from sqs-mode */
    padding-left: 110px; /* Adjust as necessary, maybe slightly more than either mode alone */
    border: 2px solid /* #013c7a; 
    /* Additional styles as needed */
}

.text-field::placeholder {
    color: #989797; /* Default placeholder text color */
    /* Add other default styles if necessary */
}

/* Style for the placeholder text when a file is attached */
.styled-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem; /* Adjust this value to the desired font size */
    pointer-events: none; /* This ensures that clicks go through to the textarea allowing users to enter a message */
    color: #ccc; /* Placeholder text color */
    display: none; /* Default to none */
  }

/* The below @media query settings can be overridden, check other @media queries at end of this file if they stop working */
@media (max-width: 768px) {
    .text-field {
        padding: 10px 50px 10px 40px; 
    }

    .text-field.file-upload-mode {
        padding: 25px 50px 5px 40px; 
    }

    .text-field.analyze-mode {
        padding: 10px 55px 10px 75px; 
    }

    .text-field::placeholder {
        font-size: 1rem; /* Adjust this value to the desired font size */
        /* You cannot position placeholder with absolute positioning, so you'll need to use padding */
    }
}
.file-preview-container {
    display: flex; /* Use flexbox to lay out children inline inside this container - note, this moves remove-attachment-btn */
    position: absolute; /* This allows us to position the preview container inside the text-field using absolute positioning */
    top: 5px; /* Adjust as needed - distance from the top edge of the parent (the .text-field-container)*/
    align-items: flex-start; /* Center children vertically */
    justify-content: flex-start; /* Align children to the start of the container */
    max-width: calc(25%); /* This is based on size of partent 'text-field-container' need to consider size, padding, margins, other elements, etc. */
    margin-top: 0px; /* These are adjustments of items inside this container */
    margin-left: 0px; /* These are adjustments of items inside this container */
    border: 1px solid #9e9797;
    border-radius: 5px;
    max-height: 25px; /* Adjust as needed */ 
    overflow: hidden; /* Ensures the file name is not visible if it's too long */
    text-overflow:ellipsis; /* Ensures the file name is truncated with an ellipsis if it's too long */

    @media (max-width: 768px) {
        max-width: 35%;
        max-height: 35%;
    } 
}

/* this is the element that stylizes the 'file-name' inside the 'file-preview-container' - which is conditional upon a file being selected*/
.file-preview-container .file-name {
    overflow: hidden; /* Ensures the file name is not visible if it's too long */
    text-overflow:ellipsis; /* Ensures the file name is truncated with an ellipsis if it's too long */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    font-size: 0.9rem; /* example font size */
    max-height: 50px;
    color: #a09c9c; /* Sets the color of the file name font */
    margin-left: 15px; /* Adds some space between the file name and the remove button, if remove button is on left */
}
/* this is a global style for the 'remove-attachment-btn' and applies to the .modal-file-itme above too and maybe others in the future*/
.remove-attachment-btn {
    border-radius: 50%; /* Make it round */
    width: 20px; /* Smaller width for the 'x' button */
    height: 20px; /* Smaller height for the 'x' button */
    border: none; /* Remove the border if there is one */
    cursor: pointer;
    font-size: 1rem; /* Adjust the font size of the 'x' if needed */
}

/* Icon wrapper */
.icons-container {
    display: flex; /* Ensures children are laid out in a row */
    justify-content: flex-end; /* Aligns children to the right */
    /*align-items: center; /* Centers children vertically */
    position: absolute; /* This allows us to position the icons inside the text-field using absolute positioning */
    right: 25px; /* Adjust as necessary to position the icons to the far right */
    bottom: 5px;
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}

/* Styles that apply to all members of the class 'option-btn' */
.option-btn {
    border: none; /* Remove button border */
    background: none; /* Remove button background */
    cursor: pointer; /* Change cursor to pointer to indicate clickable items */
    padding: 0.25rem; /* Example: 5px assuming the base font-size is 16px */
    margin: 0.05rem; /* Example: 5px assuming the base font-size is 16px */
    font-size: 1.5rem; /* This will scale with the root font size */
    z-index: 2;
}
/* Position the paperclip and microphone icons inside their 'option-btn' containerr */
.option-btn.microphone-icon,
.option-btn.send-icon {
  margin-right: 4px; /* Space between the icons and between send and edge of text-field*/
 
}

.option-btn.paperclip-icon {
  position: absolute;
  left: 30px; /* Adjust as necessary to position the icon inside the text-field */
  bottom: 5px;
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
}

  /* InputSection.css */
  /* The word 'Analyze' inside the text-field*/
  .analyze-indicator {
    position: absolute;
    left: 30px; /* Adjust as needed */
    top: 50%; /* Adjust as needed */
    transform: translateY(-50%); /* Adjust for vertical centering */
    font-size: 1rem; /* Adjust as needed */
    border-radius: 5px;
    border: 1.5px solid green;
    background-color: transparent;
    color: green; /* Or the color you use for the analyze button */
    cursor: pointer;
  }
  
  /* Hide paperclip icon when analyze mode is active */
  .analyze-mode .paperclip-icon {
    display: none;
  }

/* When a file is attached, the send button will change color */
.option-btn.send-icon.file-attached {
    /*inherits the styles from '.option-btn.send-icon' above, but can be modified below */
    /* margin: auto; /* this should centers the SVG (up arrow icon) within the flex container (i.e., the 'icons-container), but doesn't*/
    display: flex; /* Use flexbox for centering content, but this setting causes both btn's to stack */
    justify-content: center; /* Center horizontally - this centers the arrrow horizontally inside the background*/
    align-items: center; /* Center vertically - this centers the arrow vertically inside the background*/
    background-color:#000000; /* Example: change to black for the desired look */
    background-size: contain; /* or 'cover' or specific dimensions */
    border-radius: 5px; /* Make the button slightly rounded */
    width: 1.75rem; /* Example: adjust as needed */
    height: 1.75rem; /* Example: adjust as needed */
}

.option-btn:focus {
    outline: none; /* Remove focus outline for a cleaner look */
}

/* Styles for smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    html {
        font-size: 12px; /* Reduce the base font size on smaller screens */
    }

    .input-section {
        margin-bottom: 10px; /* Add bottom margin */
        /*padding-bottom: 10px; /* Additional padding to move the input field up slightly */
    }
    
    .keyboard-open .input-section {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 10; /* Make sure it's above other content */
    }

    .main-content.keyboard-open {
        height: calc(100vh - 80px); /* Ensure content area adjusts when keyboard is up */
    }

    .text-field {
        padding-right: 60px; /* Adjust right padding to create space between placeholder text and icons */
    }

    .main-content {
        height: calc(100vh); /* Commented out as it caused issues */
        padding-bottom: 10px; /* Ensure content is not obstructed by the input section */
        overflow: hidden; /* Prevent the entire main-content from scrolling */
        /*transform: scale(1); /* Prevent zooming  NOTE: this breaks the use of the top nav icons for the sidebars, so can't use it*/
    }

    input, textarea, select, button {
        font-size: 16px; /* Prevent zooming by setting font size */
    }

    .left-sidebar.show-sidebar {
        transform: translateX(0); /* Show sidebar when toggled */
        display: block; /* Ensure sidebar is displayed */
        z-index: 10; /* Ensure it is above other content */
    }

    .left-sidebar.hidden {
        display: none; /* Hide sidebar by default */
    }

    .chat-history.show-sidebar {
        transform: translateX(0); /* Show chat history when toggled */
        display: block; /* Ensure chat history is displayed */
        z-index: 10; /* Ensure it is above other content */
    }

    .chat-history.hidden {
        display: none; /* Hide chat history by default */
    }

    .main-content.sidebar-hidden .text-field-container {
        width: calc(100% - 20px); /* Adjust width to account for padding and margins */
        margin: 0 5px;
    }

    .top-nav {
        width: 100%; /* Full-width navigation bar */
        display: flex; /* Flexbox layout */
        position: absolute; /* Absolutely position the navigation bar */
        top: 0; /* Align top of the navigation bar */
        justify-content: space-between; /* Space out icons */
        align-items: center; /* Center items vertically */
        padding: 10px; /* Add padding */
        background-color: #fff; /* Background color */
        border-bottom: 1px solid #ddd; /* Add border */
        box-sizing: border-box; /* Include padding in width calculation */
    }

    .top-nav-icon {
        font-size: 30px; /* Icon size */
        padding: 2px; /* Padding around icons */
        cursor: pointer; /* Pointer cursor on hover */
    }

    #top-nav-logo {
        width: 33%; /* Adjust width */
        padding: 10px; /* Add padding */
        margin: 10px auto; /* Center the logo */
    }
}

/* Styles for larger screens (min-width: 769px) */
@media (min-width: 769px) {
    html {
        font-size: 16px; /* Larger font size for larger screens */
    }
       
    .top-nav {
        display: none; /* Hide the top-nav on larger screens */
    }   
}
