.new-chat {
  padding: 5px;
}

.new-chat > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-chat .chat-text {
  /* margin: 0; */
  margin-right: 8%;
  @media (max-width: 1175px) {
    margin-right: 2%;
  }
}

.chatIcon{
  margin-top: 14%;
}

.chat-button {
  outline: none;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* padding: 5px 15px; */
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  /* padding: 8px;  */
}

/* .logoStyles {
    height: "50px"; 
    width: "20%";
    margin-right: "8px",  
} */

#left-sidebar-logo {
  /* margin-right: 10px; */
  fill: #4a90e2;
  max-width: 100%;
}

.chat-text {
  font-size: 28px;
  color: #333;
  padding-top: 35px;
}

@media only screen and (max-width: 1360px) {
  .chat-text {
    font-size: 1.60em;
  }
  .chatIcon{
    margin-top: 16%;
  }
}
@media only screen and (max-width: 995px) {
  .chat-text {
    font-size: 1.2em;
  }
  .chatIcon{
    margin-top: 22%;
    margin-left: 5%;
  }

}
@media only screen and (max-width: 845px) {
  .chat-text {
    font-size: 1.15em;
  }
}
@media only screen and (max-width: 820px) {
  .chat-text {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 770px) {
  .chat-text {
    font-size: 1em;
  }
  .chatIcon{
    margin-top: 10%;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .chat-text {
      font-size: 1.60em;
  }
}
@media screen and (min-width: 768px) and (max-width: 795px) {
  .chat-text {
      font-size: 1em;
  }
}

@media only screen and (max-width: 375px) {
  .chat-text {
    font-size: 1.25em;
  }
}

/* Hover state */
.chat-button:hover {
  background-color: #eee;
}

.imgSetting {
  display: flex;
  width: 100%;
  height: 50px;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.grid-item {
  padding: 5px;
}
.border-style {
  border: none;
  justify-content: center;
  align-items: center;
}
.grid-item:hover {
  background-color: #ffffff;
  cursor: pointer;
}
.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
}

.sidebar-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #fff;
  text-align: center;
}

/* .sidebar-body{
    height: auto;
    box-sizing: border-box ;
    border:0 solid #ffffff;
    border-radius: 1px;
    border-width: 1px;
  } */
.sidebar-body {
  box-sizing: border-box;
  border: 1px solid #ddd; /* Border style */
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 10px; /* Adjust padding as needed */
}
.sidebar-body-time {
  font-size: large;
  line-height: 0.25rem;
}
.sidebar-body-time-title {
  opacity: 0.25;
  font-weight: 500;
  font-size: medium;
  line-height: 1rem;
  padding-top: 0.75rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  margin: 0;
}
.sidebar-body-ol:hover {
  color: #ccccd4;
  cursor: default;
}

.sidebar-body-content {
  color: #333;
}

.sidebar-body:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.historyList {
  width: auto;
  border-radius: 10px;
  padding: 8px;
  padding-left: 2px;
  margin-bottom: 0px;
  margin-top: 0px;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-decoration: inherit;
  font-weight: 500;
  font-size: 13px;
  color: #666;
  @media (max-width: 768px) {
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    padding-left: 2px;
  }
}

.historyList:hover {
  background-color: #e6e6e6; /* Lighter hover background */
}

.historyList:active,
.historyList:focus {
  background-color: darkgray;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.days {
  margin: 0px;
  color: #333; /* Darker color for the section titles */
  font-size: 16px; /* Adjusted font size */
  font-weight: 600; /* Slightly bolder font weight */
  margin-bottom: 8px; /* Add spacing between title and items */
  text-transform: uppercase; /* Make section titles uppercase */
}

.history-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.history-loading-indicator {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
.selected {
  background-color: #cfcfcf; /* Slightly lighter selected background */
  width: auto;
  border-radius: 10px;
  padding: 8px;
  padding-left: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-decoration: inherit;
  font-weight: 600; /* Updated font weight for selected item */
  font-size: 14px; /* Updated font size for selected item */
  color: #000; /* Ensure text color is darker */
  @media (max-width: 768px) {
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    padding-left: 2px;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chat-sidebar-close-button {
  /* Default display property for mobile, assuming you want it visible */
  display: block; /* Show the sidebar close button on smaller screens */

  /* Position your close button */
  @media (max-width: 768px) {
      position: absolute; 
      top: 10px;
      left: 10px;
      z-index: 10; 
      font-size: 24px; 
      cursor: pointer; 
      padding: 0px;
  }
  @media (min-width: 769px) {
      display: none; /* Hide the sidebar close button on larger screens */
  }
}

.conversation-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.conversation-item:hover {
  background-color: #f0f0f0;
}

.conversation-content {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.historyList {
  flex-grow: 1;
  cursor: pointer;
  margin: 0;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px; /* Increased font size */
  font-weight: 500;
  color: #000; /* Darker text color */
  padding: 10px 15px; /* Add more padding for better spacing */
  margin-bottom: 5px; /* Add spacing between list items */
  border-radius: 6px; /* Slightly rounded corners */
  transition: background-color 0.2s ease-in-out;
}

.options-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding-left: 10px;
}

.conversation-item:hover .options-icon,
.conversation-item .selected ~ .options-icon {
  display: block;
}