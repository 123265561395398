/* AuthStyles.css */

/* Main layout for the authentication screens */
.login-layout {
    display: flex; /* Uses flexbox for layout */
    flex-direction: row; /* Aligns children horizontally */
    align-items: flex-start; /* Aligns children to the start of the cross axis */
    justify-content: center; /* Centers children on the main axis */
    min-height: 100vh; /* Full viewport height */
    background-color: #f9f9f9; /* Light grey background color */
    font-family: 'Arial', sans-serif; /* Common font for web */
    
    @media (max-width: 768px) {  /* New Line */
        flex-direction: column;  /* New Line */
        align-items: center;  /* New Line */
        padding: 20px;  /* New Line */
    }  /* New Line */
}

/* Left column styles */
.left-column {
    flex: 0 0 55%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Aligns content to the top of the column */
    text-align: center; /* This ensures that any text within the children is also centered */
    padding: 20px; /* Keep this padding the same as .right-column to align the tops */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    
    @media (max-width: 768px) {
        flex: 0; /* Takes up 0 space when the screen is less than 768px wide */ 
        display: none;
    }
}

.logo img {
    width: 100%;
    margin: 0 auto;
    height: auto; /* Maintains aspect ratio */

    @media (max-width: 768px) {  
        width: 100%;
        padding-left: 10px; 
    }
}

/* Hide the small-screen logo on larger screens */
.logo-small-screen img {
    display: none;

    @media (max-width: 768px) {  
        display: block; /* Show these elements on small screens */
        text-align: center;
        width: 160px; /* Adjust width as needed */
        height: auto;
        margin: 0 auto; /* Center the logo */
        padding-top: 5px; /* Add some padding above the text */
        margin-bottom: 5px; /* Add spacing as needed */
    }
}

/* Styling for '#maia-text' is in 'Layout.css'*/
#maia-text {
}

/* Hide the small-screen logo on larger screens */
#maia-text-small-screen {
    display: none;

    @media (max-width: 768px) {  
        display: block; /* Show these elements on small screens */
        text-align: center;
        font-size: 16px; /* This size may still work well on mobile */
        padding-bottom: 5px; /* Add some padding below the text */
        padding-top: 5px; /* Add some padding above the text */
        margin-bottom: 20px; /* Add spacing as needed */
    }
}

/* Add styles for hero image */
.hero-image {
    width: 100%; /* Full width of the column */
    /*max-width: 600px; /* Maximum width of the image */
    height: auto; /* Maintain aspect ratio */
    padding: 20px; /* Spacing to the left of the image */

    @media (max-width: 768px) {  
        display: none; /* Hide the image on mobile */
    }
}

/* Right column styles for forms */
.right-column {
    flex: 0 0 45%; /* Takes up 60% of the space */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Aligns content to the top of the column */
    padding: 20px; /* Keep this padding the same as .left-column to align the tops */
    box-sizing: border-box; /* Include padding and border in the element's width and height */

    @media (max-width: 768px) {  
        /*padding: 20px 0px 0px 0px; */
        padding: 20px 10px;  /* New Line */
        width: 100%;  /* New Line */
        max-width: 90%;  /* New Line */
    }  /* New Line */
}

/* Container for the login and register elements */
.login-container, .register-container, .forgot-password-container {
    text-align: center; /* Centers text inside the container */
    width: 100%; /* Full width of the parent element */
    max-width: 320px; /* Maximum width of the container */
    padding: 20px; /* Padding inside the container */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    background: #fff; /* White background for the container */
    border-radius: 8px; /* Rounded corners */
    
}

/* Style for the navigation buttons (Login, Register) */
nav button {
    /* Assuming you want the styles of .option-button to apply to these buttons */
    padding: 10px 20px;
    margin: 10px; /* Adjusted to match the JSX (10px margin around buttons) */
    border: none; 
    /*background-color: #e7e7e7;*/
    background-color: transparent; /* Changed to make the button background transparent */
    cursor: pointer;
    transition: background-color 0.3s;
    color: #808080; /* Set the non-selected text color to gray */
    /*color: #007bff; /* Set the text color */
    text-transform: uppercase; /* Make the text uppercase */
    border-radius: 0; /* Set to 0 for square corners or to a value (like '4px') for rounded corners */
    font-weight: bold; /* Make the text bold */
    outline: none; /* Remove the outline to match the revised style */

    
    @media (max-width: 768px) {  
        display: none; /* Hide the image on mobile */
    }
}

/* Style for the active navigation button */
nav button:disabled {
    /* Assuming you want the .option-button.active styles when button is disabled */
    color: #007bff;
    border-bottom: 2px solid #007bff; /* Underline for the active button */
}

/* Grouping for form elements */
.form-group {
    margin-bottom: 5px; /* Space below each form group */
}

/* Style for labels in the form */
.form-group label {
    font-size: 14px; /* Font size for labels */
    display: block; /* Ensures label takes up full width */
    text-align: left; /* Aligns text to the left */
    margin-bottom: 5px; /* Space below the label */
}

/* Style for input fields, and btns in the form */
.form-group input, .btn, .btn-primary {
    box-sizing: border-box; /* Include padding and border in the width and height */
    width: 100%; /* Full width */
    padding: 10px; /* Consistent padding */
    margin: 5px 0; /* Consistent vertical margin, no horizontal margin */
    border: 1px solid #ddd; /* Consistent border */
    border-radius: 4px; /* Consistent border radius */
}

/* Overriding styles for the buttons to remove the border and adjust colors */
.btn, .btn-primary {
    border: none; /* Removing the border for buttons */
    background-color: #007bff; /* Blue background */
    color: white; /* White text color */
    cursor: pointer; /* Cursor changes to pointer on hover */
}

/* Error and success message styling */
.error, .success {
    color: red; /* Adjust as needed */
    margin-bottom: 15px;
}
.success {
    color: green; /* Adjust as needed */
}

/* Style for links, like "Forgot password?" or "Sign up" */
.link {
    display: block; /* Displays link as block to take full width */
    margin-top: 15px; /* Space above the link */
    color: #007bff; /* Blue color to indicate clickable link */
    text-decoration: none; /* No underline by default */
}

/* Hover style for links */
.link:hover {
    text-decoration: underline; /* Underline on hover for links */
}
