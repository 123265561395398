/* SettingsModal.css - Styles for the Settings Modal */

/* Modal Overlay */
.settingsModal-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Modal Content */
.settingsModal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 85%; /* Set maximum height */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 85%;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Add scroll bar when content exceeds height */
}

/* Modal Header */
.settingsModal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.settingsModal-header h2 {
  margin: 0;
}

.settingsModal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Modal Tabs */
.settingsModal-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  border-bottom: 1px solid #ccc;
}

.settingsModal-tabs li {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.settingsModal-tabs li.active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

/* Tab Content */
.settingsModal-tab-content {
  padding: 0;
  margin-top: 10px; /* Reduce or adjust margin to change space between the tab section and the content below */
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Adds bottom margin to the form, space between bottom of form and the button */
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
}
 
form input, 
form select,
form textarea { /* New Line */
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 90%; /* Adjust this percentage to your desired width for the input fields */
} 

form button {
  padding: 10px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
/*  align-self: flex-end; /* Align the button to the right */
  margin-left: auto; /* Align the button to the right */ /* New Line */
}

form button:hover {
  background-color: #218838;
}

@media (max-width: 768px) { 
  .settingsModal-content {
    width: 95%;
    padding: 15px;
  }
  .settingsModal-tabs li { 
    padding: 5px 10px;
    font-size: 14px;
  }
}