/* OverlayModal.css */

.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;  /* Use fixed to position relative to the viewport */
    top: 0;
    left: 0;
    width: 100vw;     /* Full width of the viewport */
    height: 100vh;    /* Full height of the viewport */
    background-color: rgba(0, 0, 0.5, 0.5); /* Semi-transparent background */
    padding: 2% 0;  /* Adds top and bottom padding (effectively shrinking actual modal size)*/
    z-index: 1050;    /* High z-index to ensure it sits on top of other content */
}

.modal-content {
    background: white; /* or any color you prefer */
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better focus */
    /*width: auto;  /* Adjust based on your content size */
    width: 100%;  /* Ensures modal content uses up 100% of assigned width */
    max-width: 1200px; /* Max width of the modal can be adjusted based on your design */
    border-radius: 8px; /* Optional: rounds the corners */
   /* overflow: hidden; /* Ensures no internal content spills out */
   overflow-x: hidden; /* Prevents horizontal scroll on the modal itself */
   overflow-y: auto; /* Allows vertical scrolling if needed */
}

.modal-header {
    position: relative; /* Add relative positioning to the header */
    display: flex;
    justify-content: space-between; /* Ensures space between elements */
    align-items: center; /* Vertically aligns the items in the header */
    padding: 10px; /* Padding around the header */
}

.close-button {
    position: absolute !important;
    top: 0 !important; /* Set to top corner */
    right: 0 !important; /* Set to right corner */
    cursor: pointer;
    border: none;
    background: transparent !important;
    font-size: 30px;
    margin: 0;  /* Remove any default margin */
    font-weight: normal; /* Ensure the font is not bold */
    color: #535353; /* Set the color to black, or as needed */
    padding: 0, 10px;  /* Padding for clickable area */
}

.modal-tabs {
    display: flex; /* Align tabs in a row */
    padding: 0; /* Removes default padding */
    margin: 0; /* Adjusts margin as necessary */
    margin-bottom: 20px; /* Space below the tabs */
    list-style: none; /* Removes bullet points */
}

.modal-tabs li {
    flex-grow: 1;  /* Ensures each tab takes equal space */
    text-align: center; /* Centers the text within the tab */
    padding: 10px 20px; /* Padding for clickable area */
    cursor: pointer; /* Indicates clickable */
    border-bottom: 2px solid transparent; /* Styling for inactive state */
}

.modal-tabs li.active {
    border-bottom: 2px solid blue; /* Styling for active state */
}

.modal-body{
    height: 100%;
    /*overflow: auto; */
    overflow-x: auto; /* Enables horizontal scrolling within the modal body */
    width: 100%; /* Ensures it fills its container */
}

/* Add specific styles for the Supply Sources tab content */
.supply-sources-content {
    max-width: 600px; /* Set the maximum width for the content area */
    margin-left: 50px; /* Position the content area to the left*/
}

/* Additional CSS for Supply Matching Summary table */
.supply-matching-table th {
    font-size: 13px; /* Adjust the font size as necessary */
    padding: 8px; /* Adjust padding to manage space within cells */
    background-color: #f4f4f4; /* Light grey background for headers, adjust as needed */
    color: #333; /* Dark grey text for headers, adjust as needed */
    text-align: center; /* Center aligns text */
    white-space: normal; /* Allows text to wrap */
    word-wrap: break-word; /* Ensures words can break and wrap onto the next line */
    max-width: 120px;    /* Set a max-width as per your design needs */

}

.supply-matching-table td {
    font-size: 12px; /* Adjust the font size as necessary */
    text-align: center; /* Aligns text to the left, change as needed */
    padding: 8px; /* Adjust padding to manage space within cells */
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden;    /* Keeps the text within the cell */
    text-overflow: ellipsis; /* Shows ellipsis when text overflows */
    max-width: 120px;    /* Set a max-width as per your design needs */
}

/* Styles for generate-rfq-button on Supply Matching Summary Page */
.generate-rfq-button-container {
    position: sticky;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: flex-end; /* This line ensures the button is aligned to the right */
}

/* This class is applied to the container of the entire RFQ Emails tab content. */
.rfq-emails-tab {
    max-width: 700px; /* Set a max-width for the RFQ Emails tab content area */
    margin-left: 50px; /* Position the content area to the left*/
    /*margin: 0 auto; /* Center the content area within the modal */
    padding: 0px; /* Add padding for better spacing */
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better focus */
    background-color: white; /* Ensure the background is white */
    border-radius: 8px; /* Optional: rounds the corners */
}

/* Specific styles applied to the <textarea> elements within each RFQ email. */
.rfq-email-textarea {
    width: 90%; 
    min-height: 300px; /* Sets a minimum height for the textarea */
    resize: vertical; /* Allows vertical resizing only */
    margin-bottom: 5px; /* Adds space below each textarea and before buttons */
}

/* Specific styles applied to the <to: input> elements (email addressee) within each RFQ email. */
.rfq-email-to-input {
    width: 90%; 
    padding: 3px; /* Add some space to the text field around the email address */
}

.vendor-name {
    width: 90%; 
    font-size: 14px; 
    font-weight: bold;
    background-color: rgb(200, 198, 198);
    color: white;
    padding: 2px;
    margin-top: 2px;
}

.top-buttons-container {
    display: flex;
    width: 90%; 
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 15px;
}

.top-buttons-container .buttons {
    display: flex;
    gap: 10px; /* Adds space between the buttons */
}

.send-all-button, .delete-all-button {
    padding: 5px 10px; /* Adjusts the size of the buttons, padding around the text */
    background-color: #007BFF; /* Example button color - blue */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-all-button {
    background-color: rgb(251, 113, 100); /* Example delete button color - red */
}

.email-container {
    margin-top: 0px; /* Adjusts the space bettween the top of the email block */
    margin-bottom: 30px; /* Adds more space between each email block */
}

.email-actions {
    margin-top: -25px; /* Moves buttons up closer to the email text */
    margin-bottom: 10px; /* Adds space below the buttons before the next email */
}
.send-email-button {
    background-color: rgb(150, 150, 147);
    color: white;
    padding: 5px; /* Adjusts the size of the buttons, padding around the text */
    margin: 5px; /* Adjusts the space between the buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.delete-email-button {
    background-color: rgb(251, 113, 100);
    color: white;
    padding: 5px; /* Adjusts the size of the button based on the text inside */
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.success-message {
    color: green;
    margin-top: 10px;
    font-weight: bold;
}
.send-all-button:disabled,
.send-email-button:disabled,
.delete-email-button:disabled,
.delete-all-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}


@media (max-width: 768px) {
    .modal-content {
        padding: 10px;
        max-width: 100%; /* Allows modal to take full width */
    }

    .supply-sources-content {
        max-width: 90%; /* Adjust the width for smaller screens */
        padding: 0 10px; /* Add some padding for better appearance */
    }

    .supply-matching-table {
        min-width: 600px; /* Adjust based on the minimum required width to display your content properly */
    }
    
    .supply-matching-table th, .supply-matching-table td {
        padding: 4px; /* Reduces cell padding for space efficiency */
        font-size: 11px; /* Smaller font to fit more content */
    }

    .rfq-emails-tab {
        max-width: 100%; /* Make the content area take up most of the screen */
        margin: 0 auto; /* Center the content horizontally */
        padding: 5px; /* Add padding for better spacing */
        border-radius: 5px; /* Adjust the border-radius for smaller screens */
    }
    .rfq-email-textarea {
        width: 100%; /* Make the content area take up most of the screen */
        max-width: 100%; /* Ensure the textarea takes the full width */
        margin: 0 auto; /* Center the content horizontally */
        min-height: 200px; /* Sets a minimum height for the textarea */
        font-size: 14px; /* Ensure the text is legible on small screens */
        box-sizing: border-box; /* Ensure padding is included in the width calculation */
    }
    .rfq-email-to-input, .vendor-name {
        width: 100%; 
        max-width: 100%; /* Ensure full width */
        margin: 0 auto; /* Center the elements within their container */
        margin-bottom: 10px; /* Add some space below the input */
    }

    .send-all-button, .delete-all-button {
        width: 100%; 
        padding: 4px 8px;
        font-size: 12px; /* Adjust font size for smaller screens */
        margin: 0 auto 5px; /* Center the elements within their container */
    }

    .email-container {
        margin-bottom: 20px; /* Add more space between email blocks */
        margin: 0 auto; /* Center the email containers within the tab */
    }

    .send-email-button, .delete-email-button {
        padding: 10px;
        font-size: 12px; /* Adjust font size if needed */
        margin-bottom: 5px; /* Space between buttons */
    }
}